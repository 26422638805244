var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { style: _vm.getStyle },
    [
      _c("Login", {
        on: {
          type: _vm.setStyle,
          login: function ($event) {
            return _vm.back()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }